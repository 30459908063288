export const firstNameAccessor = 'firstName';
export const lastNameAccessor = 'lastName';
export const phoneNumberAccessor = 'phoneNumber';
export const timezoneAccessor = 'timezone';
export const sourceTypeAccessor = 'sourceType';
export const participantLabelsAccessor = 'participantLabels';
export const createdAtAccessor = 'activityStats.createdAt';
export const daysSinceInvitedAccessor = 'activityStats.daysSinceInvited';
export const lastBulkEmailAccessor = 'lastBulkEmailSentAt';
export const daysSinceAppliedAccessor = 'activityStats.daysSinceApplied';
export const daysSinceParticipatedAccessor = 'activityStats.daysSinceParticipated';
export const totalIncentivesEarnedAccessor = 'totalIncentivesEarned';
export const unsubscribedAccessor = 'unsubscribed';

export const populationAttributeAccessorPrefix = `participantAttribute`;

export const buildColumns = ({
  canViewPii,
  columnOrder,
  hiddenColumns,
  maxIncentiveYear,
  minIncentiveYear,
  populationAttributes,
}) => {
  const hiddenColumnsLookUp = buildHiddenColumnsLookUp(hiddenColumns);

  const populationAttributesLookUp = buildPopulationAttributeColumnsLookUp({
    hiddenColumnsLookUp,
    populationAttributes,
  });

  const baseColumnLookUp = buildHubDefaultColumnsLookUp({
    canViewPii,
    hiddenColumnsLookUp,
    maxIncentiveYear,
    minIncentiveYear,
  });

  const columnMap = new Map([
    ...baseColumnLookUp,
    ...populationAttributesLookUp,
  ]);

  const order = (columnOrder?.length) ?
    completeColumnOrder({
      columnOrder,
      populationAttributes,
    }) :
    buildHubDefaultColumnOrder({
      maxIncentiveYear,
      minIncentiveYear,
      populationAttributes,
    });

  const columns = [];

  order.forEach((columnAccessor) => {
    const column = columnMap.get(columnAccessor);

    if (column) columns.push(column);
  });

  return columns;
};

const buildHubDefaultColumnOrder = ({
  maxIncentiveYear,
  minIncentiveYear,
  populationAttributes,
}) => ([
  firstNameAccessor,
  lastNameAccessor,
  phoneNumberAccessor,
  timezoneAccessor,
  createdAtAccessor,
  sourceTypeAccessor,
  participantLabelsAccessor,
  daysSinceInvitedAccessor,
  lastBulkEmailAccessor,
  daysSinceAppliedAccessor,
  daysSinceParticipatedAccessor,
  totalIncentivesEarnedAccessor,
  ...yearlyIncentiveAccessors(maxIncentiveYear, minIncentiveYear),
  'unsubscribed',
  ...populationAttributeAccessors(populationAttributes),
]);

export const buildHiddenColumnsLookUp = (hiddenColumns) => {
  const lookup = new Map();

  if (hiddenColumns?.length) {
    hiddenColumns.forEach((hiddenColumn) => {
      lookup.set(hiddenColumn, true);
    });
  }

  return lookup;
};

export const buildHubDefaultColumnsLookUp = ({
  canViewPii,
  hiddenColumnsLookUp,
  maxIncentiveYear,
  minIncentiveYear,
}) => {
  const hiddenColumns = hiddenColumnsLookUp || new Map();

  return new Map([
    [firstNameAccessor, {
      accessor: firstNameAccessor,
      Header: 'First name',
      hidden: !!hiddenColumns.get(firstNameAccessor),
      id: firstNameAccessor,
      meta: nonEditableMetaData,
    }],
    [lastNameAccessor, {
      accessor: lastNameAccessor,
      Header: canViewPii ? 'Last name' : 'Last Initial',
      hidden: !!hiddenColumns.get(lastNameAccessor),
      id: lastNameAccessor,
      meta: nonEditableMetaData,
    }],
    [phoneNumberAccessor, {
      accessor: phoneNumberAccessor,
      Header: 'Phone number',
      hidden: !!hiddenColumns.get(phoneNumberAccessor),
      id: phoneNumberAccessor,
      meta: nonEditableMetaData,
    }],
    [timezoneAccessor, {
      accessor: timezoneAccessor,
      Header: 'Timezone',
      hidden: !!hiddenColumns.get(timezoneAccessor),
      id: timezoneAccessor,
      meta: nonEditableMetaData,
    }],
    [sourceTypeAccessor, {
      accessor: sourceTypeAccessor,
      Header: 'Source',
      hidden: !!hiddenColumns.get(sourceTypeAccessor),
      id: sourceTypeAccessor,
      meta: nonEditableMetaData,
    }],
    [participantLabelsAccessor, {
      accessor: participantLabelsAccessor,
      Header: 'Labels',
      hidden: !!hiddenColumns.get(participantLabelsAccessor),
      id: participantLabelsAccessor,
      meta: nonEditableMetaData,
    }],
    [createdAtAccessor, {
      accessor: createdAtAccessor,
      Header: 'Date added',
      hidden: !!hiddenColumns.get(createdAtAccessor),
      id: createdAtAccessor,
      meta: nonEditableMetaData,
    }],
    [daysSinceInvitedAccessor, {
      accessor: daysSinceInvitedAccessor,
      Header: 'Last invited',
      hidden: !!hiddenColumns.get(daysSinceInvitedAccessor),
      id: daysSinceInvitedAccessor,
      meta: nonEditableMetaData,
    }],
    [lastBulkEmailAccessor, {
      accessor: lastBulkEmailAccessor,
      Header: 'Last bulk email',
      hidden: !!hiddenColumns.get(lastBulkEmailAccessor),
      id: lastBulkEmailAccessor,
      meta: nonEditableMetaData,
    }],
    [daysSinceAppliedAccessor, {
      accessor: daysSinceAppliedAccessor,
      Header: 'Last applied',
      hidden: !!hiddenColumns.get(daysSinceAppliedAccessor),
      id: daysSinceAppliedAccessor,
      meta: nonEditableMetaData,
    }],
    [daysSinceParticipatedAccessor, {
      accessor: daysSinceParticipatedAccessor,
      Header: 'Last participated',
      hidden: !!hiddenColumns.get(daysSinceParticipatedAccessor),
      id: daysSinceParticipatedAccessor,
      meta: nonEditableMetaData,
    }],
    [totalIncentivesEarnedAccessor, {
      accessor: totalIncentivesEarnedAccessor,
      Header: 'Total incentives earned',
      hidden: !!hiddenColumns.get(totalIncentivesEarnedAccessor),
      id: totalIncentivesEarnedAccessor,
      meta: nonEditableMetaData,
    }],
    [unsubscribedAccessor, {
      accessor: unsubscribedAccessor,
      Header: 'Unsubscribed',
      hidden: !!hiddenColumns.get(unsubscribedAccessor),
      id: unsubscribedAccessor,
      meta: nonEditableMetaData,
    }],
    ...buildYearlyIncentiveColumnsLookUp({
      hiddenColumnsLookUp,
      maxIncentiveYear,
      minIncentiveYear,
    }),
  ]);
};

export const buildPopulationAttributeColumnsLookUp = ({
  hiddenColumnsLookUp,
  populationAttributes,
}) => {
  if (!populationAttributes) return [];

  const hiddenColumns = hiddenColumnsLookUp || new Map();

  const lookUp = new Map();

  populationAttributes.forEach((populationAttribute) => {
    const accessor = `${populationAttributeAccessorPrefix}${populationAttribute.id}`;

    lookUp.set(accessor, {
      accessor,
      editable: true,
      id: populationAttribute.id,
      Header: populationAttribute.name,
      hidden: !!hiddenColumns.get(accessor),
      meta: populationAttribute.meta,
    });
  });

  return lookUp;
};

export const buildYearlyIncentiveColumnsLookUp = ({
  hiddenColumnsLookUp,
  maxIncentiveYear,
  minIncentiveYear,
}) => {
  const hiddenColumns = hiddenColumnsLookUp || new Map();

  const lookUp = new Map();

  yearlyIncentiveYears(maxIncentiveYear, minIncentiveYear).forEach((year) => {
    const accessor = `totalIncentives${year}`;

    lookUp.set(accessor, {
      accessor,
      Header: `Total incentives (${year})`,
      hidden: !!hiddenColumns.get(accessor),
      id: accessor,
      meta: nonEditableMetaData,
    });
  });

  return lookUp;
};

const completeColumnOrder = ({
  columnOrder,
  populationAttributes,
}) => {
  if (!populationAttributes?.length || !columnOrder?.length) return columnOrder;

  const missingPopulationAttributes = [];

  populationAttributes.forEach((populationAttribute) => {
    const accessor = `${populationAttributeAccessorPrefix}${populationAttribute.id}`;

    if (!columnOrder.includes(accessor)) missingPopulationAttributes.push(accessor);
  });

  return [
    ...columnOrder,
    ...missingPopulationAttributes,
  ];
};

export const nonEditableMetaData = {
  canDelete: false,
  canUpdate: false,
};

export const populationAttributeAccessors = (populationAttributes) => {
  if (!populationAttributes) {
    return [];
  }

  return populationAttributes.map(
    populationAttribute => `${populationAttributeAccessorPrefix}${populationAttribute.id}`,
  );
};

export const rearrangeColumnOrder = ({
  activeAccessor,
  columnOrder,
  overAccessor,
}) => {
  const reorderColumns = [...columnOrder];

  const activeColumnIndex = reorderColumns.indexOf(activeAccessor);

  const activeColumn = reorderColumns.splice(activeColumnIndex, 1);
  if (activeColumn) {
    const spliceIndex = reorderColumns.indexOf(overAccessor);
    reorderColumns.splice((spliceIndex), 0, activeColumn[0]);
  }

  return reorderColumns;
};

export const updateHiddenColumns = (accessor, hiddenColumns) => {
  if (!hiddenColumns) return [accessor];

  const index = hiddenColumns.indexOf(accessor);

  if (index === -1) {
    hiddenColumns.push(accessor);
  } else {
    hiddenColumns.splice(index, 1);
  }

  return hiddenColumns;
};

export const yearlyIncentiveAccessors = (maxIncentiveYear, minIncentiveYear) =>
  yearlyIncentiveYears(maxIncentiveYear, minIncentiveYear).map(year => `totalIncentives${year}`);

export const yearlyIncentiveYears = (maxIncentiveYear, minIncentiveYear) => {
  const years = [];

  let currentYear = maxIncentiveYear;

  if (currentYear === 0 || minIncentiveYear === 0) {
    return years;
  }

  while (currentYear >= minIncentiveYear) {
    years.push(currentYear);

    currentYear -= 1;
  }

  return years;
};
