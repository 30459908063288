import * as backendConstants from 'lib/generated_constants/participant_attributes';

export const updateSections = {
  INFO: 'participant-info',
  PASSWORD: 'participant-password',
};

const BROWSERS_FORM_INPUT_LABEL = 'Which internet browser(s) do you use?';
const COMPUTER_OS_FORM_INPUT_LABEL = 'What computer operating system(s) do you use?';
const COMPUTER_WEBCAM_FORM_INPUT_LABEL = 'Do you have a computer with a webcam?';
const DISABILITIES_FORM_INPUT_LABEL = 'Do you identify as having any of the following disabilities?';
const HOUSEHOLD_INCOME_FORM_INPUT_LABEL = 'Household income per year';
const LEVEL_OF_EDUCATION_FORM_INPUT_LABEL = 'Highest level of education';
const SMALL_BUSINESS_OWNER_FORM_INPUT_LABEL = 'Small business owner?';
const SMARTPHONE_OS_FORM_INPUT_LABEL = 'What smartphone operating system(s) do you use?';
const TABLET_OS_FORM_INPUT_LABEL = 'What tablet operating system(s) do you use?';

export const attributeSlugsToInputLabels = {
  [backendConstants.BROWSERS_SLUG]: BROWSERS_FORM_INPUT_LABEL,
  [backendConstants.COMPUTER_OS_SLUG]: COMPUTER_OS_FORM_INPUT_LABEL,
  [backendConstants.COMPUTER_WEBCAM_SLUG]: COMPUTER_WEBCAM_FORM_INPUT_LABEL,
  [backendConstants.DISABILITIES_SLUG]: DISABILITIES_FORM_INPUT_LABEL,
  [backendConstants.HOUSEHOLD_INCOME_SLUG]: HOUSEHOLD_INCOME_FORM_INPUT_LABEL,
  [backendConstants.LEVEL_OF_EDUCATION_SLUG]: LEVEL_OF_EDUCATION_FORM_INPUT_LABEL,
  [backendConstants.SMALL_BUSINESS_OWNER_SLUG]: SMALL_BUSINESS_OWNER_FORM_INPUT_LABEL,
  [backendConstants.SMARTPHONE_OS_SLUG]: SMARTPHONE_OS_FORM_INPUT_LABEL,
  [backendConstants.TABLET_OS_SLUG]: TABLET_OS_FORM_INPUT_LABEL,
};

export const attributeSlugsToDefaultOptionNames = {
  [backendConstants.CHILDREN_SLUG]: backendConstants.CHILDREN_DEFAULT_OPTION_NAME,
  [backendConstants.COMPUTER_OS_SLUG]: backendConstants.COMPUTER_OS_DEFAULT_OPTION_NAME,
  [backendConstants.SMARTPHONE_OS_SLUG]: backendConstants.SMARTPHONE_OS_DEFAULT_OPTION_NAME,
  [backendConstants.TABLET_OS_SLUG]: backendConstants.TABLET_OS_DEFAULT_OPTION_NAME,
  [backendConstants.TYPE_OF_INCOME_SLUG]: backendConstants.TYPE_OF_INCOME_DEFAULT_OPTION_NAME,
};

export * from 'lib/generated_constants/participant_attributes';
