import * as routes from 'lib/routes';

import { apiSlice } from 'api/base_slice';

import { SEGMENTS_TAG } from 'api/tag_types';
import {
  deserializeJSONApiResponse,
  headersAndCredentials,
  sortAlphabeticalByName,
} from 'api/utils';

export const segmentsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createSegment: builder.mutation({
      query: ({
        filterName,
        filterRules,
        participantPopulationId,
        teamId,
        teamRoleId,
      }) => {
        // TODO: RS-15240 Update this to use V2 API

        const params = {
          filter_name: filterName,
          filter_rules: filterRules,
          participant_population_id: participantPopulationId,
          team_id: teamId,
          team_role_id: teamRoleId,
        };

        return {
          url: routes.create_participant_population_search_filter_path(
            { participant_population_id: participantPopulationId },
          ),
          method: 'POST',
          body: params,
          ...headersAndCredentials(),
        };
      },
      invalidatesTags: [SEGMENTS_TAG],
    }),
    deleteSegment: builder.mutation({
      query: ({ populationId, segmentId }) => ({
        url: routes.api_participant_population_segment_path(
          segmentId,
          { participant_population_id: populationId },
        ),
        method: 'DELETE',
        ...headersAndCredentials(),
      }),
      invalidatesTags: (result, error, { segmentId }) => (
        [{ type: SEGMENTS_TAG, id: segmentId }]
      ),
    }),
    duplicateSegment: builder.mutation({
      query: ({
        populationId, segmentId, teamRoleId, attributes,
      }) => ({
        url: routes.api_participant_population_segment_duplicate_path(
          segmentId,
          {
            participant_population_id: populationId,
            team_role_id: teamRoleId,
          },
        ),
        method: 'POST',
        body: { data: { attributes } },
        ...headersAndCredentials(),
      }),
      invalidatesTags: (result, error, { populationId }) => (
        [{ type: SEGMENTS_TAG, id: populationId }]
      ),
      transformResponse: deserializeJSONApiResponse,
    }),
    getSegments: builder.query({
      query: ({ populationId, teamId }) =>
        routes.api_participant_population_segments_path({
          participant_population_id: populationId,
          team_id: teamId,
        }),
      providesTags: (result, error, { populationId }) =>
        [
          { type: SEGMENTS_TAG, id: populationId },
          ...(result?.data ? result.data.map(({ id }) => ({ type: SEGMENTS_TAG, id })) : []),
        ],
      transformResponse: (response) => {
        const data = sortAlphabeticalByName(deserializeJSONApiResponse(response));
        const { meta } = response;
        return { data, meta };
      },
    }),

    updateSegment: builder.mutation({
      query: ({ populationId, segmentId, attributes }) => ({
        url: routes.api_participant_population_segment_path(
          segmentId,
          { participant_population_id: populationId },
        ),
        method: 'PATCH',
        body: { data: { attributes } },
        ...headersAndCredentials(),
      }),
      invalidatesTags: (result, error, { segmentId }) => (
        [{ type: SEGMENTS_TAG, id: segmentId }]
      ),
      transformResponse: deserializeJSONApiResponse,
    }),
  }),
  overrideExisting: 'throw',
});

export const {
  useCreateSegmentMutation,
  useDeleteSegmentMutation,
  useDuplicateSegmentMutation,
  useGetSegmentsQuery,
  useUpdateSegmentMutation,
} = segmentsApi;
