import { useCallback } from 'react';
import { useOwnershipContext } from 'components/owner_boundary/context';
import { useRailsContext } from './use_rails_context';

const errorMessages = new Map<string, number>();

export function useErrorLogger() {
  const ctx = useRailsContext();
  const expirationSeconds = ctx?.env.APPSIGNAL_JS_ERROR_LOG_EXPIRATION_SECONDS ?? 30;

  const { logError } = useOwnershipContext();

  return useCallback((error: Error) => {
    const lastLoggedAt = errorMessages.get(error.message);
    const now = Date.now();

    if (!lastLoggedAt || now - lastLoggedAt > expirationSeconds * 1000) {
      logError(error);
      errorMessages.set(error.message, Date.now());
    }
  },
  [expirationSeconds, logError]);
}
