import React, {
  createContext,
  useMemo,
  useContext,
  type ReactNode,
} from 'react';
import { type JSClient } from '@appsignal/types';
import { Ownership } from 'lib/generated_constants/ownership';

const OwnershipContext = createContext<({
  owner: Ownership | 'frontend';
  logError:(error: Error) => void;
}) | null>(null)

type OwnershipProviderProps = {
  owner: Ownership | 'frontend';
  appsignal: JSClient | null;
  children: ReactNode;
}

function fallbackErrorLogger(error: Error) {
  // eslint-disable-next-line no-console
  console.error(error);
}

export function OwnershipProvider({ owner, appsignal, children }: OwnershipProviderProps) {
  const contextValue = useMemo(() => ({
    owner,
    logError: appsignal ? appsignal.sendError : fallbackErrorLogger,
  }), [appsignal, owner]);

  return (
    <OwnershipContext.Provider value={contextValue}>
      {children}
    </OwnershipContext.Provider>
  )
}

export function useOwnershipContext() {
  const ownershipContext = useContext(OwnershipContext);
  const logError = useMemo(() => (ownershipContext ? ownershipContext.logError : () => {}), [ownershipContext]);

  if (!ownershipContext) {
    return {
      owner: 'frontend' as const,
      logError,
    };
  }

  return ownershipContext;
}
