import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import * as rawTagTypes from './tag_types';

const tagTypes = Object.values(rawTagTypes);

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  tagTypes,
  endpoints: () => ({}),
});
