/* eslint-disable camelcase */
import type {
  PathParameters,
  ResponseBody,
  RequestBodyAttributes,
} from 'lib/swagger_helper';
import * as routes from 'lib/routes';

import { apiSlice } from 'api/base_slice';
import { ACCOUNT_HUB_CONFIGURATIONS } from 'api/tag_types';

import { headersAndCredentials } from 'api/utils';

export type AccountHubConfigurationsRawResponse = ResponseBody<'getAccountHubConfigurations'>;
export type AccountHubConfigurationsApiResponse = ReturnType<typeof transformResponse>;

export const accountHubConfigurationsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAccountHubConfigurations: builder.query<
      AccountHubConfigurationsApiResponse,
      PathParameters<'getAccountHubConfigurations'>
    >({
      query: ({ participant_population_id }) =>
        routes.api_participant_population_hub_configs_path(
          participant_population_id,
        ),
      providesTags: (_result, _error, { participant_population_id }) => [
        { type: ACCOUNT_HUB_CONFIGURATIONS, id: participant_population_id },
      ],
      transformResponse,
    }),
    upsertAccountHubConfiguration: builder.mutation<
      AccountHubConfigurationsApiResponse,
      RequestBodyAttributes<'upsertAccountHubConfiguration'> &
      PathParameters<'upsertAccountHubConfiguration'>
    >({
      query: ({ participant_population_id, ...configurations }) => ({
        url: routes.api_participant_population_hub_configs_path(
          participant_population_id,
        ),
        method: 'PATCH',
        body: { data: { attributes: configurations } },
        ...headersAndCredentials(),
      }),
      invalidatesTags: (_result, _error, { participant_population_id }) => [
        { type: ACCOUNT_HUB_CONFIGURATIONS, id: participant_population_id },
      ],
      transformResponse,
    }),
  }),
  overrideExisting: 'throw',
});

function transformResponse(
  response: ResponseBody<
    'getAccountHubConfigurations'> | ResponseBody<'upsertAccountHubConfiguration'
  >,
) {
  const { data } = response;

  if (!data) return undefined;

  return {
    id: data.id,
    ...data.attributes,
  };
}

export const {
  useGetAccountHubConfigurationsQuery,
  useUpsertAccountHubConfigurationMutation,
} = accountHubConfigurationsApi;
