import * as routes from 'lib/routes';

import { apiSlice } from 'api/base_slice';
import { PARTICIPANTS_TAG, TEAM_HUB_USAGE_TAG } from 'api/tag_types';
import { ACCEPT_V2_HEADER, deserializeJSONApiResponse, headersAndCredentials } from 'api/utils';

export const participantsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createParticipants: builder.mutation({
      query: ({ email, teamIds }) => {
        const params = { data: { attributes: { email } } };

        if (teamIds) params.data.team_ids = teamIds;

        return {
          url: routes.api_participants_path(),
          method: 'POST',
          body: params,
          ...headersAndCredentials(ACCEPT_V2_HEADER),
        };
      },
      invalidatesTags: [TEAM_HUB_USAGE_TAG],
    }),
    getParticipant: builder.query({
      query: ({ id, params }) => routes.api_participant_path(id, params),
      providesTags: (result, error, { id }) => [
        { type: PARTICIPANTS_TAG, id },
      ],
      transformResponse: deserializeJSONApiResponse,
    }),
    updateParticipant: builder.mutation({
      query: ({ id, params }) => ({
        url: routes.api_participant_path(id),
        method: 'PATCH',
        body: params,
        ...headersAndCredentials(),
      }),
      invalidatesTags: (result, error, { id }) => [{ type: PARTICIPANTS_TAG, id }],
    }),
    updateParticipantTeams: builder.mutation({
      query: ({ encodedFilterParams, teamIds, teamId }) => ({
        url: routes.api_teams_participants_path({ team_id: teamId }),
        method: 'PATCH',
        body: {
          team_ids: teamIds,
          participant_filter: encodedFilterParams,
        },
        ...headersAndCredentials(),
      }),
    }),
    upsertParticipants: builder.mutation({
      query: ({
        email, teamIds, teamId, populationId,
      }) => {
        const params = { data: { attributes: { email } } };

        if (teamIds) params.data.team_ids = teamIds;

        return {
          url: routes.api_participants_path({
            participant_population_id: populationId,
            team_id: teamId,
          }),
          method: 'PATCH',
          body: params,
          ...headersAndCredentials(ACCEPT_V2_HEADER),
        };
      },
      invalidatesTags: [TEAM_HUB_USAGE_TAG],
    }),
  }),
  overrideExisting: 'throw',
});

export const {
  useGetParticipantQuery,
  useUpdateParticipantMutation,
  useUpdateParticipantTeamsMutation,
  useUpsertParticipantsMutation,
} = participantsApi;
