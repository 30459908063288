import { apiSlice } from 'api/base_slice';
import * as routes from 'lib/routes';
import { deserializeJSONApiResponse, headersAndCredentials, sortAlphabeticalByTitle } from 'api/utils';
import { OPT_IN_FORMS_TAG } from 'api/tag_types';

export const participantPopulationOptInFormsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getParticipantPopulationOptInForms: builder.query({
      query: participantPopulationId => routes.api_participant_population_opt_in_forms_path({
        participant_population_id: participantPopulationId,
      }),
      transformResponse: response =>
        sortAlphabeticalByTitle(deserializeJSONApiResponse(response)),
    }),
    createOptInForm: builder.mutation({
      query: ({ participantPopulationId, attributes }) => ({
        url: routes.api_participant_population_opt_in_forms_path(participantPopulationId),
        method: 'POST',
        body: {
          data: {
            attributes,
          },
        },
        ...headersAndCredentials(),
      }),
      transformResponse: deserializeJSONApiResponse,
      invalidatesTags: [OPT_IN_FORMS_TAG],
    }),
  }),
  overrideExisting: 'throw',
});

export const {
  useGetParticipantPopulationOptInFormsQuery,
  useCreateOptInFormMutation,
} = participantPopulationOptInFormsApi;
