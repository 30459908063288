/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  populationAttributesApi,
} from 'api/population_attributes';

import {
  accountHubConfigurationsApi,
} from 'api/account_hub_configurations';

import {
  rearrangeColumnOrder,
  updateHiddenColumns,
} from 'researcher/participant_search/filtered_view/utils';

const matchPopulationAttributesFetched = isAnyOf(
  populationAttributesApi.endpoints.getPopulationAttributes.matchFulfilled,
);

const matchAccountHubConfigurationsFetched = isAnyOf(
  accountHubConfigurationsApi.endpoints.getAccountHubConfigurations.matchFulfilled,
);

const initialState = {
  columnOrder: [],
  hiddenColumns: [],
  populationAttributes: [],
};

export const columnConfigurationSlice = createSlice({
  name: 'columnConfiguration',
  initialState,
  reducers: {
    HIDE_ALL_COLUMNS: (state, action) => {
      const {
        columns,
      } = action.payload;

      const columnAccessors = columns.map(column => column.accessor);

      state.hiddenColumns = columnAccessors;
    },
    REORDER_COLUMNS: (state, action) => {
      const {
        activeAccessor,
        currentColumnOrder,
        overAccessor,
      } = action.payload;

      const reorderedColumns = rearrangeColumnOrder({
        activeAccessor,
        columnOrder: currentColumnOrder,
        overAccessor,
      });

      state.columnOrder = reorderedColumns;
    },
    SHOW_ALL_COLUMNS: (state) => {
      state.hiddenColumns = [];
    },
    TOGGLE_HIDDEN_COLUMN: (state, action) => {
      const {
        hiddenColumns,
      } = state;

      const { accessor } = action.payload;

      const updatedHiddenColumns = updateHiddenColumns(accessor, hiddenColumns);

      state.hiddenColumns = updatedHiddenColumns;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(matchPopulationAttributesFetched, (state, action) => {
      const populationAttributes = action.payload;

      return {
        ...state,
        populationAttributes,
      };
    });
    builder.addMatcher(matchAccountHubConfigurationsFetched, (state, action) => {
      const columnOrder = action.payload?.settings?.columns.order;
      const hiddenColumns = action.payload?.settings?.columns.hidden || [];

      return {
        ...state,
        columnOrder,
        hiddenColumns,
      };
    });
  },
});

export const {
  HIDE_ALL_COLUMNS,
  REORDER_COLUMNS,
  SHOW_ALL_COLUMNS,
  TOGGLE_HIDDEN_COLUMN,
} = columnConfigurationSlice.actions;

export const columnConfigurationSliceReducer = columnConfigurationSlice.reducer;
