import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LinkButton, AuthenticationType } from './components/link_button';

import styles from './new_session_picker.module.scss';

export function NewSessionPicker({ isSignin }) {
  return (
    <div className={classNames(styles.root, { [styles.centeredVertically]: isSignin })}>
      {!isSignin && (
        <h1 className={styles.header}>Let's get that account created!</h1>
      )}
      <div className={styles.linkButtons}>
        <LinkButton
          authenticationType={AuthenticationType.Researcher}
          isSignin={isSignin}
        />
        <LinkButton
          authenticationType={AuthenticationType.Participant}
          isSignin={isSignin}
        />
      </div>
    </div>
  );
}

NewSessionPicker.propTypes = {
  isSignin: PropTypes.bool.isRequired,
};
