/* eslint-disable @typescript-eslint/no-explicit-any */
// using the any type here until this endpoint gets RSwag.
// The API has a lot of conditionally included resources  so its a bit of work
// to tease it out.
import { merge } from 'lodash'
import * as routes from 'lib/routes';
import type {
  RequestBodyAttributes,
} from 'lib/swagger_helper';

import { deserializeJSONApiResponse, headersAndCredentials } from 'api/utils';
import { apiSlice } from 'api/base_slice';
import {
  PROJECT_TAG,
  PROJECT_COST_BREAKDOWN_TAG,
  PROJECT_EXPECTED_COST_DETAILS_TAG,
  PROJECT_PAYMENT_METHODS_TAG,
  PROJECT_DEMOGRAPHIC_RULES_TAG,
  PROJECT_AVAILABILITY_SETTINGS_TAG,
} from 'api/tag_types';

export const projectsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getProject: builder.query<
      any,
      {
        projectId: string;
        include?: string;
      }
    >({
      query: ({
        projectId,
        include,
      }) => routes.api_project_path(projectId, { include }),
      transformResponse: (response: any) => merge(
        deserializeJSONApiResponse(response),
        {
          meta: {
            invalidLaunchMessages: response.meta?.invalidLaunchMessages,
            invalidLaunchProps: response.meta?.invalidLaunchProps,
            firstProjectId: response.meta?.firstProjectId,
            canEdit: response.meta?.canEdit,
          },
        },
      ),
      providesTags: (_result, _error, { projectId }) => [{ type: PROJECT_TAG, id: projectId }],
    }),
    createProject: builder.mutation<
      { id: string },
      RequestBodyAttributes<'createProject'>
    >({
      query: (attributes) => ({
        url: routes.api_projects_path(),
        method: 'POST',
        body: { data: { attributes, type: 'project' } },
        ...headersAndCredentials(),
      }),
      transformResponse: deserializeJSONApiResponse,
    }),
    updateProject: builder.mutation<unknown, {
      projectId: string;
      skipProjectInvalidation?: boolean;
      attributes: unknown;
    }>({
      query: ({
        projectId,
        attributes,
      }) => ({
        url: routes.api_project_path(projectId),
        method: 'PATCH',
        body: { data: { attributes } },
        ...headersAndCredentials(),
      }),
      transformResponse: deserializeJSONApiResponse,
      invalidatesTags: (_result, _error, { projectId, skipProjectInvalidation }) => [
        ...(skipProjectInvalidation ? [] : [{ type: PROJECT_TAG, id: projectId } as const]),
        { type: PROJECT_COST_BREAKDOWN_TAG, id: projectId },
        { type: PROJECT_EXPECTED_COST_DETAILS_TAG, id: projectId },
        { type: PROJECT_PAYMENT_METHODS_TAG, id: projectId },
        { type: PROJECT_DEMOGRAPHIC_RULES_TAG, id: projectId },
        PROJECT_AVAILABILITY_SETTINGS_TAG,
      ],
    }),
    deleteProject: builder.mutation<
      unknown,
      { projectId: string | number }
    >({
      query: ({
        projectId,
      }) => ({
        url: routes.api_project_path(projectId),
        method: 'DELETE',
        ...headersAndCredentials(),
      }),
      invalidatesTags: (_result, _error, { projectId }) => [
        { type: PROJECT_TAG, id: projectId },
      ],
    }),
  }),
  overrideExisting: 'throw',
});

export const {
  useGetProjectQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
} = projectsApi;
