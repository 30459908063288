import React, {
  useMemo,
  useContext,
  createContext,
  type ReactNode,
} from 'react';
import { z } from 'zod';

import { customContextSchema } from 'lib/react_on_rails/custom_context_schema'

type ReactOnRailsContext = z.infer<typeof customContextSchema>;

export type RailsContextValue = {
  env: ReactOnRailsContext['env'],
  request: {
    csrfParam: ReactOnRailsContext['request']['csrfParam'],
    csrfToken: ReactOnRailsContext['request']['csrfToken'],
    location: ReactOnRailsContext['location']
  },
  uiState: ReactOnRailsContext['uiState'],
  user: ReactOnRailsContext['user'],
}

export const RailsContext = createContext<RailsContextValue | null>(null);

export function useRailsContext() {
  const ctx = useContext(RailsContext);

  if (!ctx) return null;

  return ctx;
}

type RailsContextProviderProps = {
  railsContext: unknown;
  children: ReactNode;
}

export function RailsContextProvider({ railsContext, children }: RailsContextProviderProps) {
  const {
    env,
    location,
    request,
    uiState,
    user,
  } = customContextSchema.parse(railsContext);

  const contextValue = useMemo(() => ({
    env,
    request: {
      ...request,
      location,
    },
    uiState,
    user,
  }), [env, location, request, uiState, user]);

  return (
    <RailsContext.Provider value={contextValue}>
      {children}
    </RailsContext.Provider>
  );
}
