export const ACCESS_GRANTS_TAG = 'AccessGrants';
export const ACCOUNT_AVAILABILITY_SETTINGS_TAG = 'AccountAvailabilitySettings';
export const ACCOUNT_CHECKLIST_TAG = 'AccountChecklist';
export const ACCOUNT_COLLABORATORS_TAG = 'AccountCollaborators';
export const ACCOUNT_HUB_CONFIGURATIONS = 'AccountHubConfigurations';
export const ACCOUNT_INTEGRATIONS_TAG = 'AccountIntegrations';
export const ACCOUNT_SIGNUP_INFO_TAG = 'AccountSignupInfo';
export const ACCOUNTS_TAG = 'Accounts';
export const BULK_EMAIL_TAG = 'BulkEmail';
export const COMMENTS_TAG = 'Comments';
export const COMMENTERS_TAG = 'Commenters';
export const DATA_JOB_TAG = 'DataJob';
export const DEFAULT_SESSION_ROLES_TAG = 'DefaultSessionRoles';
export const EMAIL_DOMAINS_TAG = 'EmailDomains';
export const EMAIL_PROFILE_TAG = 'EmailProfile';
export const EMAIL_THEME_TAG = 'EmailTheme';
export const FEATURE_FLAG_TAG = 'FeatureFlag';
export const OPEN_RECRUIT_PERIOD_TAG = 'OpenRecruitPeriod'
export const OPT_IN_FORMS_TAG = 'OptInForms';
export const ORGANIZATION_PARTICIPANTS_TAG = 'OrganizationParticipants';
export const ORGANIZATION_PROJECT_CUSTOM_EMAIL_SETS_TAG = 'OrganizationProjectCustomEmailSets';
export const ORGANIZATION_EMAIL_THEMES_TAG = 'OrganizationEmailThemes'
export const ORGANIZATION_TEAMS_TAG = 'OrganizationTeams';
export const PARTICIPANT_ATTRIBUTE_VALUES_TAG = 'ParticipantAttributeValues';
export const PARTICIPANT_FRAUD_REPORT_TAG = 'FraudReport';
export const PARTICIPANT_POPULATION_ATTRIBUTES_TAG = 'ParticipantPopulationAttributes';
export const PARTICIPANT_PROJECT_PARTICIPANTS_TAG = 'ParticipantProjectParticipants';
export const PARTICIPANT_PROJECTS_TAG = 'ParticipantProjects';
export const PARTICIPANT_SURVEY_RESPONSE_SETS_TAG = 'ParticipantSurveyResponseSets';
export const PARTICIPANT_TASK_EVENT_STATS_TAG = 'ParticipantTaskEventStats';
export const PARTICIPANTS_TAG = 'Participants';
export const PARTICIPATION_DETAILS_TAG = 'ParticipationDetails';
export const POPULATION_ATTRIBUTES_TAG = 'PopulationAttributes';
export const POPULATION_INVITE_FILTERS_TAG = 'PopulationInviteFilters';
export const POPULATION_LABELS_TAG = 'PopulationLabels';
export const PREPAID_BALANCE_ACCOUNTS_TAG = 'PrepaidBalanceAccounts';
export const PREPAID_BALANCE_INVITATIONS_TAG = 'PrepaidBalanceInvitations';
export const PREPAID_BALANCE_TAG = 'PrepaidBalance';
export const PREPAID_BALANCE_TRANSACTIONS_TAG = 'PrepaidBalanceTransactions';
export const PREPAID_BALANCES_TAG = 'PrepaidBalances';
export const PROJECT_AVAILABILITY_SETTINGS_TAG = 'ProjectAvailabilitySettings';
export const PROJECT_COLLABORATORS_TAG = 'ProjectCollaborators';
export const PROJECT_SESSIONS_SIGNUP_PAGE_TAG = 'ProjectSessionsSignupPage';
export const PROJECT_CLOSE_REQUEST_TAG = 'ProjectCloseRequest';
export const PROJECT_COST_BREAKDOWN_TAG = 'ProjectCostBreakdown';
export const PROJECT_CUSTOM_EMAILS_TAG = 'ProjectCustomEmails';
export const PROJECT_DEMOGRAPHIC_RULES_TAG = 'ProjectDemographicRules';
export const PROJECT_EMAIL_STATS_TAG = 'ProjectEmailStats';
export const PROJECT_EXPECTED_COST_DETAILS_TAG = 'ProjectExpectedCostDetails';
export const PROJECT_FILE_UPLOADS_TAG = 'ProjectFileUploads';
export const PROJECT_INVITED_PARTICIPANTS_TAG = 'ProjectInvitedParticipants';
export const PROJECT_INVOICES_TAG = 'ProjectInvoices';
export const PROJECT_LISTINGS_TAG = 'ProjectListings';
export const PROJECT_OCCUPATION_LINKS_TAG = 'ProjectOccupationLinks';
export const PROJECT_OCCUPATION_TAGS_TAG = 'ProjectOccupationTags';
export const PROJECT_PARTICIPANT_ADMIN_DATA_TAG = 'ProjectParticipantAdminData';
export const PROJECT_PARTICIPANT_APPLICATION_STATUSES_TAG = 'ProjectParticipantApplicationStatuses';
export const PROJECT_PARTICIPANTS_TAG = 'ProjectParticipants';
export const PROJECT_PAYMENT_METHODS_TAG = 'ProjectPaymentMethods';
export const PROJECT_PROJECT_PARTICIPANTS_TAG = 'ProjectProjectParticipants';
export const PROJECT_PROJECT_SKILLS_TAG = 'ProjectProjectSkills';
export const PROJECT_PROJECT_TASKS_TAG = 'ProjectProjectTasks';
export const PROJECT_PROPOSED_AVAILABILITY_RANGES_TAG = 'ProjectProposedAvailabilityRanges';
export const PROJECT_PROPOSED_AVAILABILITY_RANGE_COLLECTIONS_TAG =
  'ProjectProposedAvailabilityRangeCollections';
export const PROJECT_SESSIONS_TAG = 'ProjectSessions';
export const PROJECT_SETTINGS_TAG = 'ProjectSettings';
export const PROJECT_STATUS_UPDATES_TAG = 'ProjectStatusUpdates'
export const PROJECT_SUMMARIES_TAG = 'ProjectSummaries';
export const PROJECT_SURVEYS_TAG = 'ProjectSurveys';
export const PROJECT_SURVEY_KEYS_TAG = 'ProjectSurveyKeys';
export const PROJECT_TAG = 'Projects';
export const PROJECT_VIDEO_UPLOADS_TAG = 'ProjectVideoUploads';
export const PROJECT_ACCOUNT_ROLES_TAG = 'ProjectAccountRoles';
export const PROJECT_WORKSPACE_CALENDAR_TAG = 'ProjectWorkspaceCalendar';
export const PROJECT_WORKSPACE_SESSIONS_TAG = 'ProjectWorkspaceSessions';
export const RECRUIT_PERIOD_TAG = 'RecruitPeriod';
export const REPORT_TAG = 'Report';
export const RESPONSE_SETS_TAG = 'ResponseSets';
export const SCHEDULABLE_RANGES_TAG = 'SchedulableRanges';
export const SEGMENTS_TAG = 'Segments';
export const SESSION_PROJECT_PARTICIPANTS_TAG = 'SessionProjectParticipants';
export const SESSION_ROLES_TAG = 'SessionRoles';
export const SESSION_SIGNUP_PAGES_SESSIONS_TAG = 'SessionSignupPagesSessions';
export const SIGNATURE_REQUESTS_TAG = 'SignatureRequests';
export const STRIPE_CARDS_TAG = 'StripeCards';
export const SURVEY_PREVIEW_SECTIONS_TAG = 'SurveyPreviewSections';
export const SURVEY_SECTIONS_TAG = 'SurveySections';
export const TEAM_BILLING_INFOS_TAG = 'TeamBillingInfos';
export const TEAM_HUB_USAGE_TAG = 'TeamHubUsage';
export const TEAM_INVITES_TAG = 'TeamInvites';
export const TEAM_MEMBERS_TAG = 'TeamMembers';
export const TEAM_PARTICIPANTS_TAG = 'TeamParticipants';
export const TEAM_PROJECT_TEMPLATES_TAG = 'TeamProjectTemplates';
export const TEAM_PROJECTS_TAG = 'TeamProjects';
export const TEAM_SETTINGS_TAG = 'TeamSettings';
export const TEAM_SUBSCRIPTIONS_TAG = 'TeamSubscriptions';
export const TEAM_TAG = 'Team';
