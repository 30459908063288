import {
  createEntityAdapter,
  createSelector,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit';

import { participantsApi } from 'api/participants';
import { populationAttributesApi } from 'api/population_attributes';

import * as profileConstants from 'participant/participant_profile/constants';

const matchParticipantFetched = isAnyOf(
  participantsApi.endpoints.getParticipant.matchFulfilled,
);
const matchPopulationAttributesFetched = isAnyOf(
  populationAttributesApi.endpoints.getPopulationAttributes.matchFulfilled,
);

const populationAttributesAdapter = createEntityAdapter({
  selectId: attribute => attribute.slug,
});

export const participantProfileSlice = createSlice({
  name: 'participantProfile',
  initialState: {
    dirtyPages: [],
    participant: undefined,
    populationAttributes: populationAttributesAdapter.getInitialState(),
    redirectTo: undefined,
  },
  reducers: {
    SET_DIRTY_PAGE: (state, action) => {
      state.dirtyPages = [...(new Set([...state.dirtyPages, action.payload.pageName]))];
    },
    SET_REDIRECT_TO: (state, action) => {
      state.redirectTo = action.payload.redirectTo;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(matchParticipantFetched, (state, action) => ({
      ...state,
      participant: action.payload,
    }));
    builder.addMatcher(matchPopulationAttributesFetched, (state, action) => {
      populationAttributesAdapter.setAll(state.populationAttributes, action.payload);
    });
  },
});

export const {
  SET_DIRTY_PAGE,
  SET_REDIRECT_TO,
} = participantProfileSlice.actions;

export const participantProfileSliceReducer = participantProfileSlice.reducer;

export const selectParticipant = state => state.participantProfile.participant;
export const selectRedirectTo = state => state.participantProfile.redirectTo;

export const {
  selectAll: selectAllAttributes,
  selectById: selectAttributeBySlug,
  selectEntities: selectAttributes,
} = populationAttributesAdapter.getSelectors(state => state.participantProfile.populationAttributes);

export const selectAttributeBySlugWithMetadata = createSelector([
  selectAttributeBySlug,
], attribute => ({
  ...attribute,
  defaultOptionName: profileConstants.attributeSlugsToDefaultOptionNames[attribute.slug],
  inputLabel: profileConstants.attributeSlugsToInputLabels[attribute.slug] || attribute.name,
}));

export const selectEmploymentAttribute = state => (
  selectAttributeBySlugWithMetadata(state, profileConstants.EMPLOYMENT_STATUS_SLUG)
);

const selectDirtyPages = state => state.participantProfile.dirtyPages;

export const selectPageIsDirty = createSelector([
  selectDirtyPages,
  (_, pageName) => pageName,
], (dirtyPages, pageName) => dirtyPages.includes(pageName));
