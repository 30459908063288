import { buildProjectState } from 'researcher/project_workspace/hooks/use_get_project_state';

// the following `buildXStepFields` functions help derive the completion percentage
// of a particular step. I have mapped all relevant model validation error keys
// e.g. `:foo` from `errors.add(:foo, 'is required')` to the step that manages said field

export function buildActivityStepFields(projectPayload) {
  const {
    isInPersonInterview,
    isOnlineInterview,
    isUnmoderatedTask,
    studyTypeId,
  } = buildProjectState(projectPayload)
  const fields = [
    'estimatedTime', // Session length (Mod) || Task length (Unmod)
    'interviewTypeId',
    'studyType',
  ]

  if (isOnlineInterview) {
    // Prep instructions drawer
    fields.push('deviceRequirements')
  }

  if (studyTypeId) {
    if (isUnmoderatedTask) {
      // Deadline
      fields.push('availabilityRanges')
      // Task drawer
      fields.push('task')
      fields.push('timezone')
    } else {
      // Scheduling drawer (Attendance and location)
      fields.push('defaultModeratorId')
      // Scheduling drawer (Attendance and location)
      fields.push('defaultSessionLocation')
      // Scheduling drawer (scheduling rules)
      fields.push('timezone')

      if (isInPersonInterview) {
        // Scheduling drawer (Attendance and location)
        fields.push('location')
        // Scheduling drawer (Attendance and location)
        fields.push('locationExtraInfo')
        // Scheduling drawer (Attendance and location)
        fields.push('locationDetail')
        // Scheduling drawer (Attendance and location)
        fields.push('parkingDetails')
      }
    }
  }

  return fields
}

export function buildDetailsStepFields(projectPayload) {
  const { compensationMethodId } = projectPayload
  const fields = [
    'compensationMethodId',
    'numParticipants',
  ]

  // account for the fact that compensationInfo errors will only be present
  // once all compensationMethodId errors are satisfied on the backend.
  // HasCompensation#compensation_method_valid
  if (compensationMethodId) {
    fields.push('compensationInfo')
  }

  return fields;
}

export function buildExperienceStepFields(_projectPayload) {
  return [
    'publicDescription',
    'publicTitle',
  ]
}

export function buildRecruitmentStepFields(projectPayload) {
  const {
    isOnlineInterview,
    isPhoneInterview,
    isHubProject,
  } = buildProjectState(projectPayload);

  const fields = ['sourceType'];

  if (isHubProject) return fields;

  // survey drawer
  fields.push('surveyDraft');

  if (isOnlineInterview || isPhoneInterview) {
    // recruitment drawer
    fields.push('location')
  }

  return fields
}
