import {
  FIELD_FILTER_OPERANDS,
  ACTIVITY_STAT_FILTER_OPERANDS,
  ATTRIBUTE_FILTER_OPERANDS,
  INCENTIVE_OPERANDS,
  PARTICIPANT_LABEL_OPERANDS,
  POPULATION_ATTRIBUTE_IS_NOT_SET_OPERANDS,
  POPULATION_ATTRIBUTE_IS_SET_OPERANDS,
  PROJECT_OPERANDS,
  OPT_IN_FORM_OPERANDS,
} from 'lib/generated_constants/filters_constants';

import generateUUID from 'lib/generate_uuid';

export const FILTER_KEYS = {
  email: 'email',
  created_at: 'created_at',
  first_name: 'first_name',
  labels: 'labels',
  last_applied_at: 'last_applied_at',
  last_invited_at: 'last_invited_at',
  last_participated_at: 'last_participated_at',
  last_name: 'last_name',
  opt_in_form: 'opt_in_form',
  phone_number: 'phone_number',
  project_participation: 'project_participation',
  source: 'source',
  yearly_payment: 'yearly_payment',
  unsubscribed: 'unsubscribed',
};

export const getParamKey = () => `filter-param-key-${generateUUID()}`;

export const getDefaultParams = (isNotSetOperand) => {
  if (isNotSetOperand) {
    return {
      key: getParamKey(),
      orIsNotSet: false,
    };
  }

  return {
    key: getParamKey(),
  };
};

const defaultFilterRuleOperand = (operands, type) => {
  // Note: Some operands are limited by type, if not all operands are available
  // to each filter type.  If type is passed, only return operands relevant to that type.
  const availableOperands = type ? operands[type] : operands;

  return availableOperands[0] || {};
};

const createPopulationAttributeFilter = (attribute) => {
  const {
    id,
    name,
    type,
  } = attribute;

  const defaultAttributeFilterRuleOperand = defaultFilterRuleOperand(
    ATTRIBUTE_FILTER_OPERANDS,
    type,
  );

  const availableOperands = type ? ATTRIBUTE_FILTER_OPERANDS[type] : ATTRIBUTE_FILTER_OPERANDS;

  return {
    availableOperands,
    defaultFilterRuleShape: {
      activityStat: null,
      field: null,
      operand: defaultAttributeFilterRuleOperand.key,
      params: [getDefaultParams(POPULATION_ATTRIBUTE_IS_NOT_SET_OPERANDS[type])],
      participantPopulationAttributeId: id,
      type,
    },
    attribute,
    id,
    name,
    isSetOperand: POPULATION_ATTRIBUTE_IS_SET_OPERANDS[type],
    isNotSetOperand: POPULATION_ATTRIBUTE_IS_NOT_SET_OPERANDS[type],
  };
};

export const defaultLabelOperand = defaultFilterRuleOperand(
  PARTICIPANT_LABEL_OPERANDS,
);

const defaultBooleanOperand = defaultFilterRuleOperand(
  FIELD_FILTER_OPERANDS,
  'boolean',
);

const defaultActivityStatDateOperand = defaultFilterRuleOperand(
  ACTIVITY_STAT_FILTER_OPERANDS,
  'date',
);

const defaultDecimalOperand = defaultFilterRuleOperand(
  INCENTIVE_OPERANDS,
  'decimal',
);

const defaultIntegerOperand = defaultFilterRuleOperand(
  FIELD_FILTER_OPERANDS,
  'integer',
);

const defaultStringOperand = defaultFilterRuleOperand(
  FIELD_FILTER_OPERANDS,
  'string',
);

export const standardFilters = {
  [FILTER_KEYS.labels]: {
    availableOperands: PARTICIPANT_LABEL_OPERANDS,
    defaultFilterRuleShape: {
      activityStat: null,
      field: null,
      operand: defaultLabelOperand.key,
      params: [getDefaultParams()],
      participantPopulationAttributeId: null,
    },
    name: 'Labels',
    id: FILTER_KEYS.labels,
  },
  [FILTER_KEYS.project_participation]: {
    availableOperands: PROJECT_OPERANDS,
    defaultFilterRuleShape: {
      activityStat: null,
      field: null,
      operand: defaultFilterRuleOperand(PROJECT_OPERANDS).key,
      params: [getDefaultParams()],
      participantPopulationAttributeId: null,
    },
    addRowLabel: 'Add another project',
    name: 'Project history',
    id: FILTER_KEYS.project_participation,
  },
  [FILTER_KEYS.opt_in_form]: {
    availableOperands: OPT_IN_FORM_OPERANDS,
    defaultFilterRuleShape: {
      activityStat: null,
      field: null,
      operand: defaultFilterRuleOperand(OPT_IN_FORM_OPERANDS).key,
      params: [getDefaultParams()],
      participantPopulationAttributeId: null,
    },
    addRowLabel: 'Add another opt-in form',
    id: FILTER_KEYS.opt_in_form,
    name: 'Opt-in form history',
  },
  [FILTER_KEYS.email]: {
    availableOperands: FIELD_FILTER_OPERANDS.string,
    defaultFilterRuleShape: {
      activityStat: null,
      field: FILTER_KEYS.email,
      operand: defaultStringOperand.key,
      params: [getDefaultParams()],
      participantPopulationAttributeId: null,
      type: 'string',
    },
    id: FILTER_KEYS.email,
    name: 'Email',
  },
  [FILTER_KEYS.first_name]: {
    availableOperands: FIELD_FILTER_OPERANDS.string,
    defaultFilterRuleShape: {
      activityStat: null,
      field: FILTER_KEYS.first_name,
      operand: defaultStringOperand.key,
      params: [getDefaultParams()],
      participantPopulationAttributeId: null,
      type: 'string',
    },
    id: FILTER_KEYS.first_name,
    name: 'First name',
  },
  [FILTER_KEYS.last_name]: {
    availableOperands: FIELD_FILTER_OPERANDS.string,
    defaultFilterRuleShape: {
      activityStat: null,
      field: FILTER_KEYS.last_name,
      operand: defaultStringOperand.key,
      participantPopulationAttributeId: null,
      params: [getDefaultParams()],
      type: 'string',
    },
    id: FILTER_KEYS.last_name,
    name: 'Last name',
  },
  [FILTER_KEYS.phone_number]: {
    availableOperands: FIELD_FILTER_OPERANDS.string,
    defaultFilterRuleShape: {
      activityStat: null,
      field: FILTER_KEYS.phone_number,
      operand: defaultStringOperand.key,
      params: [getDefaultParams()],
      participantPopulationAttributeId: null,
      type: 'string',
    },
    id: FILTER_KEYS.phone_number,
    name: 'Phone number',
  },
  [FILTER_KEYS.source]: {
    availableOperands: FIELD_FILTER_OPERANDS.integer,
    defaultFilterRuleShape: {
      activityStat: null,
      field: FILTER_KEYS.source,
      operand: defaultIntegerOperand.key,
      params: [getDefaultParams()],
      participantPopulationAttributeId: null,
      type: 'integer',
    },
    id: FILTER_KEYS.source,
    name: 'First source',
  },
  [FILTER_KEYS.unsubscribed]: {
    availableOperands: FIELD_FILTER_OPERANDS.boolean,
    defaultFilterRuleShape: {
      activityStat: null,
      field: FILTER_KEYS.unsubscribed,
      operand: defaultBooleanOperand.key,
      params: [getDefaultParams()],
      participantPopulationAttributeId: null,
      type: 'boolean',
    },
    id: FILTER_KEYS.unsubscribed,
    name: 'Unsubscribed',
  },
  [FILTER_KEYS.last_applied_at]: {
    availableOperands: ACTIVITY_STAT_FILTER_OPERANDS.date,
    defaultFilterRuleShape: {
      activityStat: FILTER_KEYS.last_applied_at,
      field: null,
      operand: defaultActivityStatDateOperand.key,
      params: [getDefaultParams(true)],
      participantPopulationAttributeId: null,
      type: 'date',
    },
    id: FILTER_KEYS.last_applied_at,
    name: 'Last applied date',
    isSetOperand: 'activity_stat_date_is_set',
    isNotSetOperand: 'activity_stat_date_is_not_set',
  },
  [FILTER_KEYS.last_invited_at]: {
    availableOperands: ACTIVITY_STAT_FILTER_OPERANDS.date,
    defaultFilterRuleShape: {
      activityStat: FILTER_KEYS.last_invited_at,
      field: null,
      operand: defaultActivityStatDateOperand.key,
      params: [getDefaultParams(true)],
      participantPopulationAttributeId: null,
      type: 'date',
    },
    id: FILTER_KEYS.last_invited_at,
    name: 'Last invited date',
    isSetOperand: 'activity_stat_date_is_set',
    isNotSetOperand: 'activity_stat_date_is_not_set',
  },
  [FILTER_KEYS.last_participated_at]: {
    availableOperands: ACTIVITY_STAT_FILTER_OPERANDS.date,
    defaultFilterRuleShape: {
      activityStat: FILTER_KEYS.last_participated_at,
      field: null,
      operand: defaultActivityStatDateOperand.key,
      params: [getDefaultParams(true)],
      participantPopulationAttributeId: null,
      type: 'date',
    },
    id: FILTER_KEYS.last_participated_at,
    name: 'Last participated date',
    isSetOperand: 'activity_stat_date_is_set',
    isNotSetOperand: 'activity_stat_date_is_not_set',
  },
  [FILTER_KEYS.yearly_payment]: {
    availableOperands: INCENTIVE_OPERANDS.decimal,
    defaultFilterRuleShape: {
      activityStat: null,
      field: null,
      operand: defaultDecimalOperand.key,
      params: [getDefaultParams()],
      participantPopulationAttributeId: null,
      type: 'decimal',
    },
    id: FILTER_KEYS.yearly_payment,
    name: 'Incentives earned',
  },
  [FILTER_KEYS.created_at]: {
    availableOperands: FIELD_FILTER_OPERANDS.date,
    defaultFilterRuleShape: {
      activityStat: null,
      field: FILTER_KEYS.created_at,
      operand: 'field_date_before',
      params: [getDefaultParams()],
      participantPopulationAttributeId: null,
      type: 'date',
    },
    id: FILTER_KEYS.created_at,
    name: 'Date added',
  },
};

export const buildFilterList = ({
  populationAttributes,
}) => {
  if (!populationAttributes?.length > 0) return standardFilters;

  const attributes = {};

  populationAttributes.forEach((attribute) => {
    attributes[attribute.id] = createPopulationAttributeFilter(attribute);
  });

  return { ...attributes, ...standardFilters };
};
