import React, { createContext, useContext, useMemo } from 'react';
import propTypes from 'prop-types';

import { Toast, useToast, MessageTypes } from '@user-interviews/ui-design-system';
import { useTracking } from 'react-tracking';

import { connectedCalendarDetailsPropType } from 'common/components/connect_calendar/prop_types';
import { useAuthUserContext } from 'hooks/use_auth_user_context';

type ProjectWorkspaceProviderProps = {
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  connectedCalendarDetails: any;
  projectId: string;
  serverProject?: {
    isDraft: boolean;
    isPrivate: boolean;
    isUnmoderatedTask: boolean;
  };
};

type ProjectWorkspaceContextType = Omit<ProjectWorkspaceProviderProps, 'children'> & {
  setToastMessage: (options: { type: typeof MessageTypes[keyof typeof MessageTypes]; message: string; }) => void;
}

const ProjectWorkspaceContext = createContext<ProjectWorkspaceContextType>({} as ProjectWorkspaceContextType);

export function ProjectWorkspaceProvider({
  children,
  connectedCalendarDetails,
  projectId,
  serverProject,
}: ProjectWorkspaceProviderProps) {
  const authUserContext = useAuthUserContext();
  const { dismissMessage, messages, setMessage } = useToast();

  const { Track } = useTracking({
    eventData: {
      project_id: projectId,
      ...(authUserContext?.account && { account_id: authUserContext.account.id }),
      ...(authUserContext?.user && { user_id: authUserContext.user.id }),
    },
  })

  const contextValue = useMemo(() => ({
    connectedCalendarDetails,
    projectId,
    serverProject,
    setToastMessage: setMessage,
  }), [connectedCalendarDetails, projectId, serverProject, setMessage]);

  return (
    <ProjectWorkspaceContext.Provider value={contextValue}>
      <Toast autoDismiss messages={messages} onToastClosed={dismissMessage} />
      <Track>
        {children}
      </Track>
    </ProjectWorkspaceContext.Provider>
  );
}

ProjectWorkspaceProvider.propTypes = {
  connectedCalendarDetails: connectedCalendarDetailsPropType,
  projectId: propTypes.string.isRequired,
  serverProject: propTypes.object,
};

ProjectWorkspaceProvider.defaultProps = {
  connectedCalendarDetails: undefined,
  serverProject: undefined,
};

export function useProjectWorkspaceContext() {
  return useContext(ProjectWorkspaceContext);
}
