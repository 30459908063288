export const ACTIVITY_STEP = 'activity';
export const DETAILS_STEP = 'details';
export const EXPERIENCE_STEP = 'experience';
export const RECRUITMENT_STEP = 'recruitment';
export const SCHEDULING_DRAWER = 'scheduling_drawer'
export const PREPARATION_INSTRUCTIONS_DRAWER = 'preparation_instructions_drawer'
export const TASK_LOCATION_DRAWER = 'task_location_drawer'
export const RECRUITMENT_CRITERIA_DRAWER = 'recruitment_criteria_drawer'
export const SCREENER_SURVEY_DRAWER = 'screener_survey_drawer'
export const SCREENER_VIDEO_DRAWER = 'screener_video_drawer';
export const INTERNAL_TITLE_DESCRIPTION_MODAL = 'internal_title_description_modal'
export const URL_REGEX = /https?:\/\/\w+((:\d+)?\/\S*)?/;
export const COMPENSATION_TYPE_OTHER = 'Other';
export const CASH_COMPENSATION_OPTION = { label: 'Cash', value: 'Cash' };
export const OTHER_COMPENSATION_OPTION = { label: 'Other', value: COMPENSATION_TYPE_OTHER };
export const VISA_GIFT_CARD_COMPENSATION_OPTION = { label: 'Visa gift card', value: 'Visa gift card' };
export const ALL_THEY_PAY_COMPENSATION_OPTIONS = [
  CASH_COMPENSATION_OPTION,
  VISA_GIFT_CARD_COMPENSATION_OPTION,
  { label: 'Tango gift card', value: 'Tango gift card' },
  { label: 'Amazon gift card', value: 'Amazon gift card' },
  OTHER_COMPENSATION_OPTION,
];

export const THEY_PAY_IN_PERSON_DEFAULT_COMPENSATION = CASH_COMPENSATION_OPTION.value;
export const THEY_PAY_DEFAULT_COMPENSATION = VISA_GIFT_CARD_COMPENSATION_OPTION.value;

export const THEY_PAY_COMPENSATION_OPTION_VALUES = ['Visa gift card', 'Tango gift card', 'Amazon gift card'];

export const THEY_PAY_COMPENSATION_IN_PERSON_OPTION_VALUES =
  [CASH_COMPENSATION_OPTION.value, ...THEY_PAY_COMPENSATION_OPTION_VALUES];

export const TOAST_PROJECT_UPDATE_SUCCESS = 'Project updated.';
