import equal from 'fast-deep-equal/es6';

import {
  INCENTIVE_IS_NOT_SET_OPERAND,
  INCENTIVE_IS_SET_OPERAND,
  PROJECT_OPERANDS,
  OPT_IN_FORM_OPERANDS,
  OR_IS_NOT_SET_PARAM_KEY,
} from 'lib/generated_constants/filters_constants';

import { QueryString } from 'lib/http';
import { FILTER_KEYS, defaultLabelOperand, getParamKey } from './filter_list';

const OPTIONAL_ROW_PARAM_KEYS = [
  OR_IS_NOT_SET_PARAM_KEY,
  'yearCeiling',
  'yearFloor',
];

const EMPTY_INCENTIVE_OPERANDS = [
  INCENTIVE_IS_NOT_SET_OPERAND,
  INCENTIVE_IS_SET_OPERAND,
];

export const INVITE_FILTER_HIDDEN_FIELDS = [
  FILTER_KEYS.labels,
  FILTER_KEYS.project_participation,
];

export const PII_HIDDEN_FIELDS = [
  FILTER_KEYS.email,
  FILTER_KEYS.last_name,
  FILTER_KEYS.phone_number,
];

export const FILTER_RULE_APPLIED_SECTIONS = {
  edit: 'edit',
  inviteFilter: 'inviteFilter',
  segment: 'segment',
};

export const FILTER_RULE_PENDING_SECTIONS = {
  builder: 'builder',
  create: 'create',
  edit: 'edit',
  inviteFilter: 'inviteFilter',
};

export const appliedSectionLookup = {
  [FILTER_RULE_PENDING_SECTIONS.builder]: FILTER_RULE_APPLIED_SECTIONS.segment,
  [FILTER_RULE_PENDING_SECTIONS.create]: FILTER_RULE_APPLIED_SECTIONS.segment,
  [FILTER_RULE_PENDING_SECTIONS.edit]: FILTER_RULE_APPLIED_SECTIONS.edit,
  [FILTER_RULE_PENDING_SECTIONS.inviteFilter]: FILTER_RULE_APPLIED_SECTIONS.inviteFilter,
};

export const getIsInviteFilter = filterRuleSection => filterRuleSection === FILTER_RULE_PENDING_SECTIONS.inviteFilter;

export const defaultLabelFilterOperandKey = defaultLabelOperand.key;

const getKeylessParams = (params) => {
  const keylessParams = [];

  [...params].forEach(param => {
    const newParam = { ...param };
    delete newParam.key;
    keylessParams.push(newParam);
  });

  return keylessParams;
}

export const buildLabelFilterRule = (labelIds = []) => ({
  operand: defaultLabelFilterOperandKey,
  params: [{ ids: labelIds }],
});

const getProjectFilterKey = (jsonRule) => {
  const operandKeys = PROJECT_OPERANDS.map(operand => operand.key);

  if (operandKeys.includes(jsonRule.operand)) return FILTER_KEYS.project_participation;

  return undefined;
};

const getOptInFormFilterKey = (jsonRule) => {
  const operandKeys = OPT_IN_FORM_OPERANDS.map(operand => operand.key);

  if (operandKeys.includes(jsonRule.operand)) return FILTER_KEYS.opt_in_form;

  return undefined;
};

const getIncentiveFilterKey = (jsonRule) => {
  if (jsonRule.operand.match(/^incentive/)) return FILTER_KEYS.yearly_payment;

  return undefined;
};

const getParticipantLabelFilterKey = (jsonRule) => {
  if (jsonRule.operand.match(/^participant_label_has_any/)) return FILTER_KEYS.labels;

  return undefined;
};

export const getFilterKeyFromJsonRule = jsonRule => (
  jsonRule.participantPopulationAttributeId ||
    jsonRule.activityStat ||
    jsonRule.field ||
    getIncentiveFilterKey(jsonRule) ||
    getProjectFilterKey(jsonRule) ||
    getOptInFormFilterKey(jsonRule) ||
    getParticipantLabelFilterKey(jsonRule)
);

export const buildFromServerFilterRules = ({
  filters,
  filterRules,
}) => {
  const appliedFilterRules = filterRules.reduce((acc, appliedFilterRule) => {
    acc[getFilterKeyFromJsonRule(appliedFilterRule)] = appliedFilterRule;

    return acc;
  }, {});

  const appliedFilterKeys = Object.keys(appliedFilterRules);

  const appliedFilterFlags = appliedFilterKeys.reduce((acc, filterKey) => {
    const appliedFilterRule = appliedFilterRules[filterKey];
    const filter = filters[filterKey];

    if (filter && appliedFilterRule) {
      const { operand, params } = appliedFilterRule;

      const { isNotSetOperand, isSetOperand } = filter;

      const orIsNotSetParams = params?.some(param => param.orIsNotSet);

      acc[filterKey] = {
        isNotSetChecked: !!(operand === isNotSetOperand || orIsNotSetParams),
        isSetChecked: operand === isSetOperand,
      };
    }

    return acc;
  }, {});

  const keyedAppliedFilterRules = {};

  appliedFilterKeys.forEach(appliedFilterKey => {
    const isSetChecked = appliedFilterFlags[appliedFilterKey]?.isSetChecked;
    const isNotSetOperand = filters[appliedFilterKey]?.isNotSetOperand;
    const isIsNotSetOperand = appliedFilterRules[appliedFilterKey].operand === isNotSetOperand;
    const defaultOperand = filters[appliedFilterKey]?.defaultFilterRuleShape.operand;
    const operand = (isSetChecked || isIsNotSetOperand) ? defaultOperand : appliedFilterRules[appliedFilterKey].operand;

    keyedAppliedFilterRules[appliedFilterKey] = {
      ...appliedFilterRules[appliedFilterKey],
      operand,
      params: [...appliedFilterRules[appliedFilterKey].params].map(param => ({
        ...param,
        key: getParamKey(),
      })),
    }
  });

  return {
    filterFlags: appliedFilterFlags,
    filterKeys: appliedFilterKeys,
    filterRules: keyedAppliedFilterRules,
  };
};

// This is expected to return true if any values, including booleans, are set.
export const getHasValueSet = param => {
  const {
    ceiling,
    days,
    endAt,
    endDate,
    floor,
    ids,
    projectId,
    startAt,
    startDate,
    value,
  } = param;

  // Due to value possibly being false it needs to be last option
  const valueToCheck = ceiling || days || endAt || endDate || floor || ids ||
    projectId || startAt || startDate || value;

  return valueToCheck !== null &&
    valueToCheck !== undefined &&
    valueToCheck.toString().trim() !== '';
};

export const hasValueSet = params => params.some((param) => getHasValueSet(param));

export const hasApplicableFilterRule = ({ filterFlags, filterRules }) => (
  Object.keys(filterRules).some(filterKey => (
    filterFlags[filterKey]?.isNotSetChecked ||
    filterFlags[filterKey]?.isSetChecked ||
    EMPTY_INCENTIVE_OPERANDS.includes(filterRules[filterKey].operand) ||
    hasValueSet(filterRules[filterKey].params)
  ))
);

const preparePendingFilterRule = ({
  filterFlags,
  pendingFilterRule,
  isNotSetOperand,
  isSetOperand,
}) => {
  const preparedRule = { ...pendingFilterRule };

  const emptyParams = [{}];

  if (filterFlags?.isSetChecked) {
    preparedRule.params = emptyParams;
    preparedRule.operand = isSetOperand;
  } else if (filterFlags?.isNotSetChecked) {
    if (!hasValueSet(preparedRule.params) || preparedRule.operand === isSetOperand) {
      preparedRule.operand = isNotSetOperand;
      preparedRule.params = emptyParams;
    } else {
      preparedRule.params =
        pendingFilterRule.params.map(param => ({ ...param, orIsNotSet: true }));
    }
  } else if (hasValueSet(preparedRule.params)) {
    if (isNotSetOperand) {
      preparedRule.params =
        pendingFilterRule.params.map(param => ({ ...param, orIsNotSet: false }));
    }
  } else if (!EMPTY_INCENTIVE_OPERANDS.includes(preparedRule.operand)) {
    return null;
  }

  preparedRule.params = getKeylessParams(preparedRule.params);

  return preparedRule;
};

export const buildFilterRulesToApply = ({
  filterFlags,
  filters,
  pendingFilterRules,
}) => Object.keys(pendingFilterRules).reduce((acc, filterKey) => {
  const filterRule = preparePendingFilterRule({
    filterFlags: filterFlags[filterKey],
    pendingFilterRule: pendingFilterRules[filterKey],
    isNotSetOperand: filters[filterKey].isNotSetOperand,
    isSetOperand: filters[filterKey].isSetOperand,
  });

  if (filterRule) acc[filterKey] = filterRule;

  return acc;
}, {});

export const encodeFilterRules = filterRules => (
  QueryString.base64Encode(Object.values(filterRules))
);

export const hasSegmentChanged = ({
  activeSegmentId, segmentsById, appliedFilterRules,
}) => {
  if (!activeSegmentId) return false;

  const activeSegment = segmentsById[activeSegmentId];

  if (!activeSegment) return false;

  const { filterRules } = segmentsById[activeSegmentId];

  const appliedFilterKeys = Object.keys(appliedFilterRules);

  if (appliedFilterKeys.length !== filterRules.length) return true;

  return filterRules.some((filterRule) => {
    const filterKey = getFilterKeyFromJsonRule(filterRule);

    const appliedFilterRule = appliedFilterRules[filterKey];

    if (!appliedFilterRule) return true;

    const keylessAppliedFilterRule = {
      ...appliedFilterRule,
      params: getKeylessParams(appliedFilterRule.params),
    }

    if (!equal(keylessAppliedFilterRule, filterRule)) return true;

    return false;
  });
};

export const getRowParams = operand => {
  const params = Object.keys(operand.params)
    .filter(param => !OPTIONAL_ROW_PARAM_KEYS.includes(param))
    .reduce((parsedParams, paramKey) => {
    // Params either map directly to their type (string value) or to a more complex structure
    // so we will unify them into one object interface every time the operand is set
      const paramValue = operand.params[paramKey];

      let parsedParam;
      if (paramValue instanceof Object) {
        parsedParam = {
          attributes: {}, label: undefined, ...paramValue, key: paramKey,
        };
      } else {
        parsedParam = {
          attributes: {}, label: undefined, key: paramKey, type: paramValue,
        };
      }

      return {
        ...parsedParams,
        [paramKey]: parsedParam,
      };
    }, {})

  // Will only ever be one param object
  return Object.values(params)[0] || {};
};
