export const HUB_INVITE_RULES_HEADER = 'Edit Hub invite rules';

export const PROJECT_INVITE_LIMIT_TITLE = 'Project invite limits';

export const PROJECT_INVITE_LIMIT_MIN = 1;
export const PROJECT_INVITE_LIMIT_MAX = 99;

export const PROJECT_INVITE_LIMIT_OUT_OF_RANGE_ERROR =
  `Project invite limit must be between ${PROJECT_INVITE_LIMIT_MIN} and ${PROJECT_INVITE_LIMIT_MAX}.`;

export const PROJECT_INVITE_LIMIT_ENABLED_ERROR =
  'Please enter a number to set your project invite limit.';

export const PROJECT_INVITE_LIMIT_INPUT_LABEL = 'Project invite multiplier';
export const PROJECT_INVITE_LIMIT_ENABLED_LABEL = 'Project invite multiplier enabled';

export const PROJECT_INVITE_LIMIT_TEXT =
  'Set the maximum number of invites a researcher can send per project to prevent over recruiting. ' +
  'Invitation limits are set as a multiple of a project\'s requested participants.';
export const PROJECT_INVITE_LIMIT_LINK_TEXT = 'Learn more about invitation limits';
export const PROJECT_INVITE_LIMIT_FORM_TEXT = 'x number of requested participants on project';

export const INVITE_FILTER_RULES_TITLE = 'Invite filter rules';

export const PUBLISH_INVITE_RULES = 'Publish changes';

export const PUBLISH_INVITE_RULES_SUCCESS_MESSAGE = 'Changes to Hub invite rules saved successfully';
export const PUBLISH_INVITE_RULES_ERROR_MESSAGE = 'There was an error saving Hub invite rules';

export const UNSUBSCRIBED_FILTER_KEY = 'unsubscribed';

export const UNSUBSCRIBED_PARTICIPANT_ALERT_MESSAGE =
  'Unsubscribed participants are automatically excluded from invites.';

export const MULTI_TEAM_ORG_ALERT_MESSAGE =
  'Invite rules affect all teams within your organization.';
