import PropTypes from 'prop-types';
import * as routes from 'lib/routes';
import participantImage from 'images/sign-in-participant.png';
import researcherImage from 'images/sign-in-researcher.png';

import styles from './link_button.module.scss';

export const AuthenticationType = {
  Researcher: 'Researcher',
  Participant: 'Participant',
};

export function LinkButton({ authenticationType, isSignin }) {
  const {
    img,
    path,
    preText,
    title,
  } = getLinkButtonAttributes({ authenticationType, isSignin });

  return (
    <a
      className={styles.linkButton}
      href={path}
    >
      <img alt="" src={img} />
      <p className={styles.preText}>{preText}</p>
      <p className={styles.title}>{title}</p>
    </a>
  );
}

function getLinkButtonAttributes({ authenticationType, isSignin }) {
  if (!(authenticationType in AuthenticationType)) {
    throw new Error(`invalid authentication type: ${authenticationType}`);
  }

  const {
    img,
    signInPath,
    signupPath,
    title,
  } = {
    [AuthenticationType.Participant]: {
      img: participantImage,
      signInPath: routes.participant_signin_path(),
      signupPath: routes.participant_signup_path(),
      title: 'Participant',
    },
    [AuthenticationType.Researcher]: {
      img: researcherImage,
      signInPath: routes.account_signin_path(),
      signupPath: routes.account_signup_path(),
      title: 'Researcher',
    },
  }[authenticationType];

  return {
    img,
    title,
    preText: isSignin ? 'SIGN IN AS A' : 'SIGN UP AS A',
    path: isSignin ? signInPath : signupPath,
  };
}

LinkButton.propTypes = {
  authenticationType: PropTypes.oneOf(
    Object.values(AuthenticationType),
  ).isRequired,
  isSignin: PropTypes.bool.isRequired,
};
